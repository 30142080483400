<template>
  <div class='profileLayout-padding mobile-full-height'>
    <v-snackbar class="pa-0" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-row v-if="$vuetify.breakpoint.lgAndUp" justify="center" no-gutters>
      <v-col cols="2" md="2" class="bank-profile-side-panel" :class="$vuetify.breakpoint.mdAndUp ? 'my-12' : 'my-4'">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col cols="10" :class="'my-12'">
        <router-view></router-view>
      </v-col>
    </v-row>
    <v-row v-else no-gutters class="mobile-full-height">
      <v-col cols="1" class="bank-profile-side-panel hidden-sm-and-down" :class="$vuetify.breakpoint.mdAndUp ? 'my-12' : 'my-4'">
        <app-bank-and-profile-side-panel></app-bank-and-profile-side-panel>
      </v-col>
      <v-col cols="12">
        <router-view></router-view>
      </v-col>
    </v-row>
    <app-referral class="hidden-md-and-down"></app-referral>
  </div>
</template>
<script>
import { uiHelper, locale } from '@/util'
import AppBankAndProfileSidePanel from '@/components/layout/BankAndProfileSideMenu.vue'
import AccountBank from '@/assets/svg/profile/bank.svg'
import AccountDeposit from '@/assets/svg/profile/deposit.svg'
import AccountHistory from '@/assets/svg/profile/history.svg'
import AccountInbox from '@/assets/svg/profile/inbox.svg'
import AccountPassword from '@/assets/svg/profile/password.svg'
import AccountProfile from '@/assets/svg/profile/profile.svg'
import AccountReward from '@/assets/svg/profile/reward.svg'
import AccountVip from '@/assets/svg/profile/vip.svg'
import AccountWithdrawal from '@/assets/svg/profile/withdrawal.svg'
import AppReferral from '@/components/member/referral'
import { PAYMENT_MEMBER_BANK_ACCOUNT, PAYMENT_BANK } from '@/store/payment.module'
import { SESSION } from '@/constants/constants'
export default {
  name: 'profileLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.mmemberProfile_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: `${locale.getMessage('meta.mmemberProfile_description')}` },
      { name: 'keyword', content: `${locale.getMessage('meta.mmemberProfile_keyword')}` }
    ]
  },
  components: {
    AppBankAndProfileSidePanel,
    AccountBank,
    AccountDeposit,
    AccountHistory,
    AccountInbox,
    AccountPassword,
    AccountProfile,
    AccountReward,
    AccountVip,
    AccountWithdrawal,
    AppReferral
  },
  data: () => ({
    openNav: false,
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {},
  watch: {
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = ''
        this.alertMessage = ''
      }
    }
  },
  created() {
    this.getMemberBankAccount()
    this.getBank()
  },
  methods: {
    getBank() {
      let obj = {
        bankName: '',
        bankCode: '',
        currency: uiHelper.getCurrency(),
        Page: '',
        ItemsPerpage: '',
        orderBy: '',
        order: ''
      }
      this.$store.dispatch(`${PAYMENT_BANK}`, { obj })
    },
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_MEMBER_BANK_ACCOUNT}`, { obj })
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss">
.mobile-full-height {
  //height: 100%;
}

.profileLayout-padding{
  padding-left:48px ;
  padding-right:48px ;
}
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}
.desktop_card {
  border-radius: 20px !important;
  background-color: var(--v-title_color2-base) !important;
  height: 660px;
  label {
    color: #3a3a3a;
  }
  .v-card__title {
    margin-left: 26px;
  }
  .icon-btn {
    &:hover {
      fill: #0c2aab !important;
      path {
        fill: #0c2aab !important;
      }
    }
  }
}
.profile-detail-card {
  border-radius: 10px !important;
  margin: 0px 40px;
  border: 1px solid var(--v-base) !important;
  font-size: 14px;
  .v-card__title {
    padding: 0px 40px;
  }
  label {
    color: #3a3a3a;
  }
  label.field-name {
    color: #acacac;
  }
  .v-label {
    font-size: 14px;
  }
}
.profile-tab-list-container {
  padding-bottom: 20px;
  padding-top: 20px;
}
.profile-tab-list-item {
  display: inline-block;
  padding: 0 10px;
  .profile-tab-list-item-button {
    background-image: linear-gradient(to bottom, #273270, #1c244f);
    letter-spacing: 0;
    min-width: 150px !important;
    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}

.profile-page-form {
  padding: 0px 20px 25px;
  max-width: 600px;
  margin: auto;
}

.bank-balance {
  background-color: #121734;
  height: 36px;
  .v-divider {
    height: 100%;
    display: inline-block;
    border-color: red;
    border-right: 5px solid var(--v-primary-base) !important;
  }
  .balance-amount {
    min-width: 100px;
  }
}

.bank-navigation-button {
  letter-spacing: 0;
  border-radius: 0px;
  padding: 24px !important;
  &.v-btn--active {
    span {
      font-weight: bold;
      color: var(--v-navigator_active-base);
    }
  }
  span {
    display: inline-block;
  }
}

.bank-page-title {
  border-bottom: 4px solid var(--v-title_color-base);
}

.bank-transaction-stepper {
  box-shadow: none;
  min-height: 350px;
}

@media (max-width: 1500px) {
  // .bank-profile-side-panel {
  //   min-width: 250px;
  // }
}
@media (max-width: 1350px) {
}
@media (max-width: 1263px) {
  .bank-page-title {
    font-size: 16px;
  }

  /*.bank-navigation-button {
    padding: 10px 20px !important;
    min-height: auto;
    height: auto;
  }*/
}
@media (max-width: 959px) {
  .desktop_card {
    border-radius: 10px !important;
    height: 100%;
    background-color: #fff !important;
    font-size: 14px;
    min-height: 330px;
    label {
      color: #3a3a3a;
    }
    .v-card__title {
      padding: 20px 0px 0px 20px;
      margin-left: 12px;
      label {
        font-size: 18px !important;
      }
    }

  }
  .profileLayout-padding{
    padding:unset !important;
  }
  .profile-detail-card {
    border-radius: 10px !important;
    margin: 0px;
    padding: 0px;
    border: 1px solid var(--v-base) !important;
    font-size: 14px;
  }
}

@media (max-width: 411px) {
  .desktop_card {
    border-radius: 10px !important;
    height: 100%;
    background-color: #fff !important;
    font-size: 14px;
    min-height: 400px;
    label {
      color: #3a3a3a;
    }
    .v-card__title {
      padding: 20px 0px 0px 20px;
      margin-left: 12px;
      label {
        font-size: 18px !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .desktop_card {
    border-radius: 10px !important;
    height: 100%;
    background-color: #fff !important;
    font-size: 14px;
    min-height: 250px;
    label {
      color: #3a3a3a;
    }
    .v-card__title {
      padding: 20px 0px 0px 20px;
      margin-left: 12px;
      label {
        font-size: 18px !important;
      }
    }
  }
}
</style>
